.progress-bar{
    height: 15px;
    background-color: $gray-400;
    border-bottom: 1px solid $gray-200;
    //box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down(sm) {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        display: block;
        top: 0px;
        z-index: 1000;
    }

    .progress-bar-bar{
        height: 15px;
        background-color: $progress-bar-color;
        transition: width 0.5s;
    }

}