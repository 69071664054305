.react-autosuggest__suggestions-container--open{
    margin: 0;

    .address-cant-find{
        background-color: $gray;
        color: white;
        font-weight: 700;
    }

    .address-suggestion{
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0.7rem;
        padding-right: 0.7rem;
        cursor: pointer;
    }
    
    @supports (display: grid) {
        @include media-breakpoint-up(md) { 
            height: 0;
        }
    }

    ul{
        background-color: $white;
        border: 1px solid $gray-400;
        margin-top: 6px;
        border-radius: 4px;
        color: $gray !important;
        font-size: 1.0em;
        
        list-style: none;
        padding-left: 0;

        li[aria-selected=true]{
            background-color: $success;
            color: $white;

            .address-suggestion{
                background-color: $success;
            }
        }
    }
}