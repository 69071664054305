// Custom variables here

@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');


$font-family-sans-serif: Lato,Helvetica,Arial,sans-serif;

$green: rgb(68, 164, 72);
$yellow: #FACD31;
$orange: #F36D21;
$teal: #1FB2AD;
$gray: #2E2E2E;
$blue: #2149AC;
$purple: rgb(087, 013, 158);
$light-purple: #E6DCF0;

$red: red;

$white:    #fff;
$gray-100: lighten($gray, 90%);
$gray-200: lighten($gray, 78%);
$gray-300: lighten($gray, 70%);
$gray-400: lighten($gray, 60%);
$gray-500: lighten($gray, 50%);
$gray-600: lighten($gray, 40%);
$gray-700: lighten($gray, 30%);
$gray-800: lighten($gray, 20%);
$gray-900: $gray;


// Overrides
$primary:       $green;
$secondary:     $gray;
$success:       $purple;
$info:          #169599;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray;


$progress-bar-color: $purple;
$footer-strip-color: $gray;

$input-btn-padding-y: 0.7rem;
$input-btn-padding-x: 1.8rem;

$input-btn-padding-y-sm: 0.7rem;
$input-btn-padding-x-sm: 1.8rem;

$input-btn-padding-y-lg: 0.7rem;
$input-btn-padding-x-lg: 1.8rem;

$border-radius:               .2rem;
$border-radius-lg:            .2rem;
$border-radius-sm:            .2rem;

$headings-font-family: Lato,Helvetica,Arial,sans-serif;
$headings-font-weight: 900;

// Header

$header-padding-top-desktop: 30px;
$header-padding-bottom-desktop: 30px;
$header-text-top-desktop: 0px;
$header-text-font-size-desktop: 30px;
$header-text-font-weight-desktop: 500;
$header-logo-margin-left-desktop: 6px;
$header-logo-width-desktop: 200px;
$header-logo-height-desktop: 76px;

$header-padding-top-mobile: 12px;
$header-padding-bottom-mobile: 6px;
$header-text-top-mobile: -6px;
$header-text-font-size-mobile: 20px;
$header-text-font-weight-mobile: 500;
$header-margin-left-mobile: 0px;
$header-logo-width-mobile: 140px;
$header-logo-height-mobile: 56px;

$header-text-font-family: lato, sans-serif;
$header-text-color: $green;
$header-text-letter-spacing: -0.05em;

*{
    -webkit-font-smoothing: antialiased;
}

@import '../index.scss';
@import './chu-header.scss';