// Custom variables here

$enable-responsive-font-sizes: true;

$container-max-widths: (
  sm: 540px,
  md: 600px,
  lg: 760px,
  xl: 800px
);

$font-size-base:              1rem; // Assumes the browser default, typically `16px`

$line-height-base:            1.5;
$h1-font-size:  $font-size-base * 2.5 ;
$h2-font-size:  $font-size-base * 2.3;
$h3-font-size:  $font-size-base * 1.75;
$h4-font-size:  $font-size-base * 1.5;
$h5-font-size:  $font-size-base * 1.25;
$h6-font-size:  $font-size-base;
$headings-font-weight:        $headings-font-weight !default;
$headings-line-height:        1.2;


@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';
@import '~@fortawesome/fontawesome-free/css/all.css';

@import './util/size';

@import './components/app';
@import './components/header';
@import './components/progress-bar';
@import './components/step';
@import './components/footer-desktop';
@import './components/footer-mobile';
@import './components/title-component';
@import './components/contact';
@import './components/legal';
@import './components/modal';
@import './components/show-legal-button';
@import './components/one-page-form';

@import './elements/buttons';
@import './elements/text';
@import './elements/grid';
@import './elements/button-multi-select';
@import './elements/icon-card';
@import './elements/vertical-divider';
@import './elements/input';
@import './elements/estimate-price';
@import './elements/price';
@import './elements/final-price';
@import './elements/fill-button';
@import './elements/sections';
@import './elements/icon-grid';
@import './elements/date-picker';
@import './elements/accordion';
@import './elements/button-container';
@import './elements/button';
@import './elements/cover-options';
@import './elements/quote';
@import './elements/form-name';
@import './elements/specified-items';
@import './elements/specified-item';
@import './elements/HelpIcon';
@import './elements/select';
@import './elements/loading-indicator';
@import './elements/braintree';
@import './elements/divider-bar';
@import './elements/breadcrumbs';
@import './elements/number-picker';
@import './elements/keyline';
@import './elements/checkbox';
@import './elements/product-choice';
@import './elements/section-flexi';
@import './elements/cover-option-table';
@import './elements/price-hover';
@import './elements/cover-options-selector-mobile';
@import './elements/button-menu';
@import './elements/final-price-section-save-edit.scss';
@import './elements/instalments-table.scss';
@import './elements/address-autocomplete.scss';
