.instalments-table{
    margin-bottom: 20px;
    font-size: 0.9em;
    
    th{
        vertical-align: top;
    }

    td:nth-child(1){
        font-weight: 400;
        padding-right: 10px;
    }

    td:nth-child(2){
        font-weight: 600;
    }

    td{
        vertical-align: top;
    }

}

.instalments-schedule-table{
    margin-bottom: 12px;
    td, th{
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 4px;
        padding-left: 12px;
        padding-right: 12px;
        font-weight: 600 !important;
    }

    th{
        background-color: rgba(0, 0, 0, 0.1);
    }
}