.input {

    .is-invalid{
        .input-errors{
            margin-top: 0.5em;
            width: 100%;
            .invalid-feedback{
                display: block;
                line-height: 1.25em;
                font-weight: 500;
            }
        }
    }

    .with-onClick[disabled] {
        pointer-events:none;
    }

    .main-label{
        font-weight: 600;
    }

    .input-group > div[role="combobox"]{
        width: 100%;
        z-index: 1;
        
        .react-autosuggest__suggestions-container--open{
            margin: 0;

            .address-cant-find{
                background-color: $gray;
                color: white;
                font-weight: 700;
            }

            .address-suggestion{
                padding-top: 1rem;
                padding-bottom: 1rem;
                padding-left: 0.7rem;
                padding-right: 0.7rem;
                font-size: 0.909em !important;
            }
            
            @supports (display: grid) {
                @include media-breakpoint-up(md) { 
                    height: 0;
                }
            }

            ul{
                background-color: $white;
                border: 1px solid $gray-400;
                margin-top: 6px;
                border-radius: 4px;
                color: $gray !important;
                
                list-style: none;
                padding-left: 0;

                li[aria-selected=true]{
                    background-color: $success;
                    color: $white;

                    .address-suggestion{
                        background-color: $success;
                    }
                }
            }
        }
    }

    input, textarea{
        //font-size: 1.1em;
        color: $gray !important;
    }

    &.large input, &.large textarea{
        font-size: 1.5em;
    }

    .input-caption{
        width: 100%;
        padding-top: 4px;
        opacity: 0.75;
        line-height: 1.25;
    }

    &.centered{
        margin-left: auto;
        margin-right: auto;

        input, textarea{
            text-align: center;
            font-weight: 700;
            color: $gray;
            letter-spacing: -0.02em;
        }
    }

}

.form-control, .custom-select{
    padding-left: 1em;
    padding-right: 1em;
}